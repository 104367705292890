import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
/***** Pages ****/

const StepsForms = Loadable(lazy(() => import("../views/StepsForm/StepsForms")));
const Users = Loadable(lazy(() => import("../views/Users/Users")));
const CreateEditUser = Loadable(lazy(() => import("../views/Users/CreateEditUser")));
const Dashboard = Loadable(lazy(() => import("../views/Dashboard/Dashboard")));
const Profile = Loadable(lazy(() => import("../views/Profile/Profile")));
const Help = Loadable(lazy(() => import("../views/Help/Help")));
const GeneralLogs = Loadable(lazy(() => import("../views/Relatories/GeneralLogs")));
const AccessLogs = Loadable(lazy(() => import("../views/Relatories/AccessLogs")));
const SystemLogs = Loadable(lazy(() => import("../views/Relatories/SystemLogs")));
const SizingLogs = Loadable(lazy(() => import("../views/Relatories/SizingLogs")));
const Historics = Loadable(lazy(() => import("../views/Historic/Historics")));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));
const Login = Loadable(lazy(() => import("../views/auth/Login")));
const RecoverPassword = Loadable(lazy(() => import("../views/auth/RecoverPassword")));
const Register = Loadable(lazy(() => import("../views/auth/Register")));
/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/sizing", exact: true, element: <StepsForms /> },
      { path: "/users", exact: true, element: <Users /> },
      { path: "/create-edit-users", exact: true, element: <CreateEditUser /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/profile", exact: true, element: <Profile /> },
      { path: "/general-logs", exact: true, element: <GeneralLogs /> },
      { path: "/access-logs", exact: true, element: <AccessLogs /> },
      { path: "/system-logs", exact: true, element: <SystemLogs /> },
      { path: "/sizing-logs", exact: true, element: <SizingLogs /> },
      { path: "/historics", exact: true, element: <Historics /> },
      { path: "/help", exact: true, element: <Help /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "login", element: <Login />},
      { path: "recover-password", element: <RecoverPassword />},
      { path: "register", element: <Register />},
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
