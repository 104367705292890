import { createContext, useEffect, useState, useMemo } from 'react';
import { getCurrentUser } from './../services/currentUser/requests';
import { useDispatch } from 'react-redux';
import { ResetUser, SetUser } from '../store/user/UserSlice';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const refreshUserProfile = async () => {
    if (token) {
      const { data } = await getCurrentUser();

      if (data.metadata.result >= 1) {
        
        const userData = data.data;
        dispatch(SetUser(userData));
        Object.keys(userData).flatMap((key) => {
          // Dados que devem ser desconsiderados no redirecionamento para o perfil
          const nonRelevantData = ['email_verified_at', 'remember_token', 'updated_at', 'mobile_verified_at', 'password', 'id_company', 'hash', 'last_app', 'avatar'];

          // Veifica se a chave tem valor negativo e se não esta incluso nos dados relevantes
          if (!userData[key] && !nonRelevantData.includes(key)) {
            navigate('profile?dados_incompletos=true');
          }
        });
        return;
      }

      dispatch(ResetUser());
      return
    }

    dispatch(ResetUser());
  };

  return (
    <AuthContext.Provider value={{ refreshUserProfile }}>
      {children}
    </AuthContext.Provider>
  );
}