import axios from '../axios-instance';
import '../token-interceptor';

export function updateCurrentUser(data) {
  return axios.put('/user', data);
}

export function updateCurrentUserAvatar(formData) {
  return axios.post('/user/avatar', formData);
}

export function getCurrentUser() {
  return axios.get('/user', {});
}

export function deleteUserAcc(idUser, data) {
  //troca o status do user para expired;
  return axios.put(`/user/${idUser}/status`, data);
}