import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {} 
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SetUser: (state, action) => {
      state.user = action.payload;
    },
    ResetUser: (state) => {
      state.user = {};
    },
  },
});

export const {
  SetUser,
  ResetUser,
} = UserSlice.actions;

export default UserSlice.reducer;
